import React, { useContext, useRef, useState } from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useAuth } from "../../core/Auth";
import {
  employerLogin,
  employerUserVerifyToken,
  getUserByToken,
  login,
} from "../../core/_requests";
import clsx from "clsx";

//LOGIN SCHEMA
const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Wrong email format")
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Email is required"),
  password: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Password is required"),
});
//INITIAL VALUE
const initialValues = {
  email: "",
  password: "",
};

//FUNCTION START
const EmployerLogin = () => {
  //SUBMIT LOGIN START
  const [loading, setLoading] = useState(false);
  const { saveAuth, setCurrentUser } = useAuth();
  const [showAlert, setShowAlert] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClose = () => {
    setShowAlert(false);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      try {
        const { data: auth } = await employerLogin(
          values.email,
          values.password
        );
        saveAuth(auth);
        const { data: user } = await employerUserVerifyToken(auth.api_token);
        setCurrentUser(user);
        //  localStorage.setItem("forte", user);
      } catch (error) {
        saveAuth(undefined);
        setStatus("The login details are incorrect");
        setSubmitting(false);
        setLoading(false);
      }
    },
  });
  const [activeTab, setActiveTab] = useState("employee");

  const handleButtonClick = (tab: any) => {
    setActiveTab(tab);
  };
  return (
    <>
      <form onSubmit={formik.handleSubmit} className="custom-form">
        {formik.status && showAlert ? (
          <div className="mb-lg-15 alert alert-danger">
            <div className="alert-text font-weight-bold">
              {formik.status}
              <button
                onClick={handleClose}
                type="button"
                className="close"
                data-dismiss="alert"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
        ) : null}
        <div className="mb-4">
          <label className="form-label">Email address</label>
          <input
            type="email"
            {...formik.getFieldProps("email")}
            className={clsx(
              "form-control",
              {
                "is-invalid": formik.touched.email && formik.errors.email,
              },
              {
                "is-valid": formik.touched.email && !formik.errors.email,
              }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className="fv-plugins-message-container">
              <span role="alert">{formik.errors.email}</span>
            </div>
          )}
        </div>
        <div className="mb-5">
          <label className="form-label">Password</label>
          <div className="input-group with-ico">
            <input
              type={showPassword ? "text" : "password"}
              {...formik.getFieldProps("password")}
              className={clsx(
                "form-control",
                {
                  "is-invalid":
                    formik.touched.password && formik.errors.password,
                },
                {
                  "is-valid":
                    formik.touched.password && !formik.errors.password,
                }
              )}
            />

            <span
              className="input-group-text c-pointer"
              onClick={handleTogglePassword}
            >
              <span className="material-symbols-outlined">
                {showPassword ? "visibility_off" : "visibility"}
              </span>
            </span>
          </div>
          {formik.touched.email && formik.errors.password && (
            <div className="fv-plugins-message-container">
              <span role="alert">{formik.errors.password}</span>
            </div>
          )}
          <p className="text-end mt-2 fs-13">
            <Link to="/forget-password">Forgot Password?</Link>
          </p>
        </div>
        <div className="text-center">
          <button
            type="submit"
            className="btn btn-asphalt min-w mb-4 mt-2"
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && <span className="indicator-label">Login</span>}
            {loading && (
              <span className="indicator-progress" style={{ display: "block" }}>
                Please wait...
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
          <p className="mb-0">
            Don’t have an account? <Link to="/signUp">Create an Account </Link>
          </p>
        </div>
      </form>
    </>
  );
};

export { EmployerLogin };
