import * as Yup from "yup";

export const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Wrong email format")
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Email is required"),

  password: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Password is required"),
});

export const registerSchema = Yup.object().shape({
  firstName: Yup.string()
    .max(50, "minimum 3 letters")
    .max(50, "Maximum 50 letters")
    .required("First Name is required"),
  lastName: Yup.string()
    .min(1, "Minimum 3 letters")
    .max(50, "Maximum 50 letters")
    .required("Last Name is required"),
  mobile: Yup.string()
    .matches(/^[-+]?[\d]+$/, "Mobile number should contain only numbers")
    .min(10, "Minimum 10 numbers")
    .required("Mobile is required"),
  email: Yup.string()
    .email("Invalid email address")
    .min(3, "Minimum 3 letters")
    .required("Email is required"),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters long")
    .matches(/[A-Z]/, "Password must contain at least one capital letter")
    .matches(
      /[!@#$%^&*]/,
      "Password must contain at least one special character (!@#$%^&*)"
    )
    .max(50, "Maximum 50 letters")
    .required("Password is required"),
  confirmPassword: Yup.string()
    .required("Confirm Password is required")
    .test(
      "passwords-match",
      "Both passwords should be the same",
      function (value) {
        return this.parent.password === value;
      }
    ),
});
