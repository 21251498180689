import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { useAuth } from "../../core/Auth";
import { getUserByToken, login } from "../../core/_requests";
import clsx from "clsx";
import { loginSchema } from "../../core/_schema";
import * as Strings from "../../core/_constants";

//INITIAL VALUE
const initialValues = {
  email: "",
  password: "",
};

//FUNCTION START
const EmployeeLogin = () => {
  const [loading, setLoading] = useState(false);
  const { saveAuth, setCurrentUser } = useAuth();
  const [showAlert, setShowAlert] = useState(true);
  const [showPassword, setShowPassword] = useState(false);

  // Destructuring assignment for formik
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      try {
        const { data: auth } = await login(values.email, values.password);
        saveAuth(auth);
        const { data: user } = await getUserByToken(auth.api_token);
        setCurrentUser(user);
      } catch (error) {
        saveAuth(undefined);
        setStatus("The login details are incorrect");
        setSubmitting(false);
        setLoading(false);
      }
    },
  });
  sessionStorage.setItem("fpEmail", formik.values.email);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  return (
    <React.Fragment>
      <form onSubmit={formik.handleSubmit} className="custom-form">
        {formik.status && showAlert ? (
          <div className="mb-lg-15 alert alert-danger">
            <div className="alert-text font-weight-bold">
              {formik.status}
              <button
                onClick={handleCloseAlert}
                type="button"
                className="close"
                data-dismiss="alert"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
        ) : null}
        <div className="mb-4">
          <label className="form-label">Email address</label>
          <input
            type="email"
            {...formik.getFieldProps("email")}
            className={clsx(
              "form-control",
              {
                "is-invalid": formik.touched.email && formik.errors.email,
              },
              {
                "is-valid": formik.touched.email && !formik.errors.email,
              }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className="fv-plugins-message-container">
              <span role="alert">{formik.errors.email}</span>
            </div>
          )}
        </div>
        <div className="mb-5">
          <label className="form-label">Password</label>
          <div className="input-group with-ico">
            <input
              type={showPassword ? "text" : "password"}
              {...formik.getFieldProps("password")}
              className={clsx(
                "form-control",
                {
                  "is-invalid":
                    formik.touched.password && formik.errors.password,
                },
                {
                  "is-valid":
                    formik.touched.password && !formik.errors.password,
                }
              )}
            />

            <span
              className="input-group-text c-pointer"
              onClick={handleTogglePassword}
            >
              <span className="material-symbols-outlined">
                {showPassword ? "visibility_off" : "visibility"}
              </span>
            </span>
          </div>
          {formik.touched.password && formik.errors.password && (
            <div className="fv-plugins-message-container">
              <span role="alert">{formik.errors.password}</span>
            </div>
          )}
          <p className="text-end mt-2 fs-13">
            <Link to="/forget-password">Forgot Password?</Link>
          </p>
        </div>
        <div className="text-center">
          <button
            type="submit"
            className="btn btn-asphalt min-w mb-4 mt-2"
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && (
              <span className="indicator-label">{Strings.LOGIN}</span>
            )}
            {loading && (
              <span className="indicator-progress" style={{ display: "block" }}>
                Please wait...
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
          <p className="mb-0">
            Don’t have an account?{" "}
            <Link to="/signUp">{Strings.CREATE_ACCOUNT}</Link>
          </p>
        </div>
      </form>
    </React.Fragment>
  );
};

export { EmployeeLogin };
