import React, { useContext, useEffect, useState } from "react";
import { removeAuth, useAuth } from "../../auth";
import { getUserProfile } from "../../auth/core/_requests";

import { Link, useNavigate, useParams } from "react-router-dom";
import { AlertContext } from "../../auth/component/alert-context";
import { AlertBox } from "../../auth/component/alert-message";
import { pursueEmployment } from "../profile/profile-form/core/requests";
import { EmployerHeader } from "./EmployerHeader";

const ViewProfileInfo = () => {
  const navigate = useNavigate();
  const { show, message, type } = useContext(AlertContext);

  const { currentUser, logout } = useAuth();
  const [profile, setProfile] = useState<any>();
  const { id } = useParams();
  useEffect(() => {
    getUserProfile(id).then((res) => {
      setProfile(res.data.Data);
    });
  }, []);

  const dateString = profile?.updatedAt;
  const date = new Date(dateString);
  const lastUpdateDate = date.toLocaleDateString("en-US", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });
  let strengthArray = [];
  let valuessArray = [];
  let joblokkingArray = [];
  let workmodeArray = [];
  let preferedRolesArray = [];
  if (profile?.topStrengths) {
    var strength = profile?.topStrengths;
    strengthArray = strength.split(",");
  } else {
    strengthArray = [];
  }
  if (profile?.topValues) {
    var valuess = profile?.topValues;
    valuessArray = valuess.split(",");
  } else {
    valuessArray = [];
  }
  if (profile?.preferedJob) {
    var jobb = profile?.preferedJob;
    joblokkingArray = jobb.split(",");
  } else {
    joblokkingArray = [];
  }
  if (profile?.modeOfWork) {
    var workmod = profile?.modeOfWork;
    workmodeArray = workmod.split(",");
  } else {
    workmodeArray = [];
  }
  if (profile?.preferedRole) {
    var prefRole = profile?.preferedRole;
    preferedRolesArray = prefRole.split(",");
  } else {
    preferedRolesArray = [];
  }

  function goBack() {
    navigate(-1);
  }

  return (
    <>
      <section className="container-fluid">
        {/* <!-- Header Starts here --> */}
        <EmployerHeader />
        {/* <!-- Header Ends here --> */}
        {/* <!-- Content Section Starts here --> */}
        <section className="content-sec row py-4">
          <div className="container-fluid pt-0 pt-md-3">
            {show && (
              <AlertBox
                type={type == "1" ? "success" : "error"}
                message={message}
              />
            )}

            <div className="container">
              <div className="row">
                <div className="col-12 col-md-6">
                  <a
                    className="btn btn-asphalt btn-sm"
                    style={{ padding: "5px 18px", marginBottom: "10px" }}
                    onClick={goBack}
                  >
                    Back to dashboard
                  </a>
                  <h2 className="page-title mb-0">My Profile</h2>
                  <p className="mb-0">
                    <small className="text-gray">
                      Last updated on {lastUpdateDate}
                    </small>
                  </p>
                </div>
              </div>
              <div className="row profile-content mt-3">
                <div className="col-12 col-lg-8">
                  {/* <!-- Avatar Sec Starts --> */}
                  <div className="snippet">
                    <div className="cont profile-sec">
                      <div className="avatar-sec">
                        <div className="img-wrap">
                          <img
                            className="img-fluid"
                            src={profile?.profile}
                            alt="Candidate Name"
                          />
                        </div>
                        <div className="avatar-cont">
                          <h2 className="mb-1">
                            {profile?.firstName + " " + profile?.lastName}
                          </h2>
                          <ul className="list-inline with-dot fs-13 mb-0">
                            <li className="list-inline-item text-gray">
                              Full Stack Developer
                            </li>
                            <li className="list-inline-item text-gray">
                              {profile?.institution}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- Avatar Sec Ends --> */}
                  {/* <!-- For <991px Starts --> */}
                  {/* <!-- Personal Information Starts --> */}
                  <div className="snippet w-100 mb-4 mt-4 d-inline-block d-lg-none">
                    <div className="head">
                      <h5 className="mb-0">Personal Information</h5>
                      <Link to="#">Edit</Link>
                    </div>
                    <div className="cont">
                      <div className="ico-row mb-3">
                        <div className="ico">
                          <span className="material-symbols-outlined">
                            {profile?.gender === 1
                              ? "male"
                              : profile?.gender === 2
                              ? "female"
                              : "transgender"}
                          </span>
                        </div>
                        <p className="fs-13 mb-0">
                          {" "}
                          {profile?.gender === 1
                            ? "Male"
                            : profile?.gender === 2
                            ? "Female"
                            : "Others"}
                        </p>
                      </div>
                      <div className="ico-row mb-3">
                        <div className="ico">
                          <span className="material-symbols-outlined">
                            domain
                          </span>
                        </div>
                        <p className="fs-13 mb-0">{profile?.institution}</p>
                      </div>
                      <div className="ico-row mb-3">
                        <div className="ico">
                          <span className="material-symbols-outlined">
                            call
                          </span>
                        </div>
                        <p className="fs-13 mb-0">XXXXX XXXXX</p>
                      </div>
                      <div className="ico-row mb-3">
                        <div className="ico">
                          <span className="material-symbols-outlined">
                            mail
                          </span>
                        </div>
                        <p className="fs-13 mb-0">XXX@MAIL.COM</p>
                      </div>
                      <div className="ico-row mb-4 align-items-start">
                        <div className="ico">
                          <span className="material-symbols-outlined">
                            location_on
                          </span>
                        </div>
                        <p className="fs-13 mb-0">
                          {profile?.address}
                          <br />
                          {profile?.city}.{profile?.state}
                          <br />
                          {profile?.country}-{profile?.pincode}
                        </p>
                      </div>
                      <div className="cont-row mb-3 align-items-start">
                        <p className="fs-13 fw-med mb-2">My Strengths</p>
                        <div className="badge-sec d-flex flex-wrap">
                          {strengthArray?.map((item: any, i: any) => {
                            return (
                              <span className="chips" key={i}>
                                {item}
                              </span>
                            );
                          })}
                        </div>
                      </div>
                      <div className="cont-row align-items-start">
                        <p className="fs-13 fw-med mb-2">My Values</p>
                        <div className="badge-sec d-flex flex-wrap">
                          {valuessArray?.map((item: any, i: any) => {
                            return (
                              <span className="chips" key={i}>
                                {item}
                              </span>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- Personal Information Ends --> */}
                  {/* <!-- Expectation Starts --> */}
                  <div className="snippet w-100 mb-4 d-inline-block d-lg-none">
                    <div className="head">
                      <h5 className="mb-0">Expectations</h5>
                      <Link to="#">Edit</Link>
                    </div>
                    <div className="cont">
                      <div className="expect-salary mb-4">
                        <p className="mb-0">
                          <small>Expected Annual Salary</small>
                        </p>
                        <h5 className="mb-0">$ {profile?.expectedSalary}</h5>
                      </div>
                      <div className="cont-row mb-3 align-items-start">
                        <p className="fs-13 fw-med mb-2">Jobs looking for</p>
                        <div className="badge-sec d-flex flex-wrap">
                          {joblokkingArray?.map((item: any, i: any) => {
                            return (
                              <span className="chips" key={i}>
                                {item}
                              </span>
                            );
                          })}
                        </div>
                      </div>
                      <div className="cont-row mb-3 align-items-start">
                        <p className="fs-13 fw-med mb-1">
                          Mode of work looking for
                        </p>
                        <ul className="list-inline with-dot">
                          {workmodeArray?.map((item: any, i: any) => {
                            return (
                              <li className="list-inline-item" key={i}>
                                {item}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                      <div className="cont-row mb-3 align-items-start">
                        <p className="fs-13 fw-med mb-1">
                          Kind of roles preferred
                        </p>
                        <ul className="list-inline with-dot">
                          {preferedRolesArray?.map((item: any, i: any) => {
                            return (
                              <li className="list-inline-item" key={i}>
                                {item}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                      <div className="cont-row align-items-start">
                        <p className="fs-13 fw-med mb-1">
                          Message to hiring manager
                        </p>
                        <p>
                          <small>{profile?.message}</small>
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* <!-- Expectation Ends --> */}
                  {/* <!-- Social Media Starts --> */}
                  {/* <div className="snippet w-100 mb-4 d-inline-block d-lg-none">
                    <div className="head">
                      <h5 className="mb-0">Social Media</h5>
                      <Link to="#">Edit</Link>
                    </div>
                    <div className="cont">
                      <div className="cont-row mb-3 align-items-start">
                        {profile?.socialmedia?.map((item: any, i: any) => {
                          return (
                            <>
                              <p className="fs-13 fw-med mb-1">{item?.name}</p>
                              <span> {item?.link}</span>
                              <br />
                              <br />
                            </>
                          );
                        })}
                      </div>
                    </div>
                  </div> */}
                  {/* <!-- Social Media Ends --> */}

                  {/* <!-- For <991px Ends --> */}
                  {/* <!-- Scrollspy Nav Starts --> */}
                  <nav className="pill-navbar" id="profile-detail">
                    <ul className="nav nav-pills">
                      <li className="nav-item">
                        <a className="nav-link active" href="#work-exp-detail">
                          <span className="material-symbols-outlined ico">
                            business_center
                          </span>
                          Work Experience
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="#visa-detail">
                          <span className="material-symbols-outlined ico">
                            demography
                          </span>
                          VISA
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="#skill-detail">
                          <span className="material-symbols-outlined ico">
                            dashboard
                          </span>
                          Skills
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="#edu-detail">
                          <span className="material-symbols-outlined ico">
                            school
                          </span>
                          Education
                        </a>
                      </li>
                    </ul>
                  </nav>
                  {/* <!-- Scrollspy Nav Ends --> */}
                  {/* <!-- Scrollspy Content Starts --> */}
                  <div
                    className="mt-lg-4 mt-0"
                    data-bs-spy="scroll"
                    data-bs-target="#profile-detail"
                    data-bs-offset="0"
                    tabIndex={0}
                  >
                    {/* <!-- Work Experience Starts --> */}
                    <div className="snippet mb-4" id="work-exp-detail">
                      <div className="head">
                        <h5 className="mb-0">
                          Work Experience{" "}
                          <small>({profile?.experienceYears} )</small>
                        </h5>
                        <Link to="#">Edit</Link>
                      </div>
                      <div className="cont">
                        <div className="row">
                          <div className="col-12 col-xl-7">
                            <ul className="list-unstyled exp-timeline mb-0">
                              {profile?.workedorganisations?.map(
                                (item: any, i: any) => {
                                  return (
                                    <li className="timeline-item" key={i}>
                                      <p className="mb-0 fs-13 fw-med">
                                        {item?.role}
                                      </p>
                                      <ul className="list-inline with-dot text-gray">
                                        <li className="list-inline-item">
                                          {item?.organisation}
                                        </li>
                                        <li className="list-inline-item">
                                          {item?.expYears}
                                        </li>
                                        {/* <li className="list-inline-item">
                                          2 Years
                                        </li> */}
                                      </ul>
                                    </li>
                                  );
                                }
                              )}
                            </ul>
                          </div>
                          <div className="col-12 col-xl-5 mb-3">
                            <div className="row">
                              <div className="col-12 mb-4">
                                <p className="fs-13 fw-med mb-1">
                                  Current Annual Salary
                                </p>
                                <p className="mb-0">
                                  $ {profile?.annualSalary}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <p className="fw-med">Previous Technologies</p>
                            {profile?.technologies?.map((item: any, i: any) => {
                              return (
                                <div
                                  className="cont-row mb-3 align-items-start"
                                  key={i}
                                >
                                  <p className="mb-0">
                                    <small className="fw-med">
                                      {item?.name}
                                    </small>
                                  </p>
                                  <p className="mb-0">
                                    <small className="text-gray">
                                      {item?.workedFor}
                                    </small>
                                  </p>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!-- Work Experience Ends --> */}
                    {/* <!-- Visa Detail Starts --> */}
                    <div className="snippet mb-4" id="visa-detail">
                      <div className="head">
                        <h5 className="mb-0">VISA</h5>
                        <Link to="#">Edit</Link>
                      </div>
                      <div className="cont">
                        <div className="cont-row row">
                          <div className="col-6 mb-3">
                            <p className="fs-11 text-gray mb-1">VISA Status</p>
                            <p className="fs-13 mb-0">
                              {profile?.authorizedUs
                                ? "US Citizen"
                                : "Not US Citizen"}
                            </p>
                          </div>
                          <div className="col-6 mb-3">
                            <p className="fs-11 text-gray mb-1">
                              Are you ready to work on Forte W2?
                            </p>
                            <p className="fs-13 mb-0">
                              {profile?.workOnFort ? "Yes" : "No"}
                            </p>
                          </div>
                          <div className="col-6">
                            <p className="fs-11 text-gray mb-1">
                              Are you ready to sign NDA & contract to work with
                              Forte?
                            </p>
                            <p className="fs-13 mb-0">
                              {" "}
                              {profile?.contractWithFort ? "Yes" : "No"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!-- Visa Detail Ends --> */}
                    {/* <!-- Skill Detail Starts --> */}
                    <div className="snippet mb-4" id="skill-detail">
                      <div className="head">
                        <h5 className="mb-0">Skills</h5>
                        <Link to="#">Edit</Link>
                      </div>
                      <div className="cont">
                        <div className="cont-row mb-4">
                          <div className="badge-sec d-flex flex-wrap">
                            {profile?.skills?.map((item: any, i: any) => {
                              return (
                                <span className="chips" key={i}>
                                  {item?.name}
                                  <small> {item?.preficiency}</small>
                                </span>
                              );
                            })}
                          </div>
                        </div>
                        <div className="cont-row">
                          <p className="fw-med">Languages</p>
                          <div className="cont-row align-items-start">
                            <div className="row">
                              {profile?.languages?.map((item: any, i: any) => {
                                return (
                                  <div className="col-md-6 mb-3" key={i}>
                                    <p className="fs-13 fw-med mb-1">
                                      {item?.name}
                                    </p>
                                    <ul className="list-inline with-dot text-gray mb-0">
                                      {item?.read ? (
                                        <li className="list-inline-item">
                                          Read
                                        </li>
                                      ) : (
                                        ""
                                      )}
                                      {item?.write ? (
                                        <li className="list-inline-item">
                                          Write
                                        </li>
                                      ) : (
                                        ""
                                      )}
                                      {item?.speak ? (
                                        <li className="list-inline-item">
                                          Speak
                                        </li>
                                      ) : (
                                        ""
                                      )}
                                    </ul>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!-- Skill Detail Ends --> */}
                    {/* <!-- Education Detail Starts --> */}
                    <div className="snippet mb-4" id="edu-detail">
                      <div className="head">
                        <h5 className="mb-0">Education</h5>
                        <Link to="#">Edit</Link>
                      </div>
                      <div className="cont">
                        <div className="cont-row align-items-start">
                          <div className="row">
                            {profile?.education?.map((item: any, i: any) => {
                              var datExp = item?.expiryDate;
                              const dateE = new Date(datExp);
                              const ExpiryeeDat = dateE.toLocaleDateString(
                                "en-US",
                                {
                                  day: "2-digit",
                                  month: "short",
                                  year: "numeric",
                                }
                              );
                              return (
                                <div className="col-md-6 mb-3" key={i}>
                                  <p className="fs-13 fw-med mb-1">
                                    {item?.certificateName}
                                  </p>
                                  <ul className="list-inline with-dot text-gray mb-0">
                                    <li className="list-inline-item">
                                      {" "}
                                      {item?.certificateNo}
                                    </li>
                                    <li className="list-inline-item">
                                      Expired on {ExpiryeeDat}
                                    </li>
                                  </ul>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!-- Education Detail Ends --> */}
                  </div>
                  {/* <!-- Scrollspy Content Ends --> */}
                </div>
                <div className="col-12 col-lg-4 d-none d-lg-inline-block">
                  {/* <!-- Personal Information Starts --> */}
                  <div className="snippet mb-4">
                    <div className="head">
                      <h5 className="mb-0">Personal Information</h5>
                      <Link to="#">Edit</Link>
                    </div>
                    <div className="cont">
                      <div className="ico-row mb-3">
                        <div className="ico">
                          <span className="material-symbols-outlined">
                            {profile?.gender === 1
                              ? "male"
                              : profile?.gender === 2
                              ? "female"
                              : "transgender"}
                          </span>
                        </div>
                        <p className="fs-13 mb-0">
                          {" "}
                          {profile?.gender === 1
                            ? "Male"
                            : profile?.gender === 2
                            ? "Female"
                            : "Others"}
                        </p>
                      </div>
                      <div className="ico-row mb-3">
                        <div className="ico">
                          <span className="material-symbols-outlined">
                            domain
                          </span>
                        </div>
                        <p className="fs-13 mb-0">{profile?.institution}</p>
                      </div>
                      <div className="ico-row mb-3">
                        <div className="ico">
                          <span className="material-symbols-outlined">
                            call
                          </span>
                        </div>
                        <p className="fs-13 mb-0">{profile?.mobile}</p>
                      </div>
                      <div className="ico-row mb-3">
                        <div className="ico">
                          <span className="material-symbols-outlined">
                            mail
                          </span>
                        </div>
                        <p className="fs-13 mb-0">{profile?.emailId}</p>
                      </div>
                      <div className="ico-row mb-4 align-items-start">
                        <div className="ico">
                          <span className="material-symbols-outlined">
                            location_on
                          </span>
                        </div>
                        <p className="fs-13 mb-0">
                          {profile?.address}
                          <br />
                          {profile?.city}.{profile?.state}
                          <br />
                          {profile?.country}-{profile?.pincode}
                        </p>
                      </div>
                      <div className="cont-row mb-3 align-items-start">
                        <p className="fs-13 fw-med mb-2">My Strengths</p>
                        <div className="badge-sec d-flex flex-wrap">
                          {strengthArray?.map((item: any, i: any) => {
                            return (
                              <span className="chips" key={i}>
                                {item}
                              </span>
                            );
                          })}
                        </div>
                      </div>
                      <div className="cont-row align-items-start">
                        <p className="fs-13 fw-med mb-2">My Values</p>
                        <div className="badge-sec d-flex flex-wrap">
                          {valuessArray?.map((item: any, i: any) => {
                            return (
                              <span className="chips" key={i}>
                                {item}
                              </span>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- Personal Information Ends --> */}
                  {/* <!-- Expectation Starts --> */}
                  <div className="snippet mb-4">
                    <div className="head">
                      <h5 className="mb-0">Expectations</h5>
                      <Link to="#">Edit</Link>
                    </div>
                    <div className="cont">
                      <div className="expect-salary mb-4">
                        <p className="mb-0">
                          <small>Expected Annual Salary</small>
                        </p>
                        <h5 className="mb-0">$ {profile?.expectedSalary}</h5>
                      </div>
                      <div className="cont-row mb-3 align-items-start">
                        <p className="fs-13 fw-med mb-2">Jobs looking for</p>
                        <div className="badge-sec d-flex flex-wrap">
                          {joblokkingArray?.map((item: any, i: any) => {
                            return (
                              <span className="chips" key={i}>
                                {item}
                              </span>
                            );
                          })}
                        </div>
                      </div>
                      <div className="cont-row mb-3 align-items-start">
                        <p className="fs-13 fw-med mb-1">
                          Mode of work looking for
                        </p>
                        <ul className="list-inline with-dot">
                          {workmodeArray?.map((item: any, i: any) => {
                            return (
                              <li className="list-inline-item" key={i}>
                                {item}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                      <div className="cont-row mb-3 align-items-start">
                        <p className="fs-13 fw-med mb-1">
                          Kind of roles preferred
                        </p>
                        <ul className="list-inline with-dot">
                          {preferedRolesArray?.map((item: any, i: any) => {
                            return (
                              <li className="list-inline-item" key={i}>
                                {item}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                      <div className="cont-row align-items-start">
                        <p className="fs-13 fw-med mb-1">
                          Message to hiring manager
                        </p>
                        <p>
                          <small>{profile?.message}</small>
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* <!-- Expectation Ends --> */}
                  {/* <!-- Social Media --> */}
                  {/* <div className="snippet">
                    <div className="head">
                      <h5 className="mb-0">Social Media</h5>
                      <Link to="#">Edit</Link>
                    </div>
                    <div className="cont">
                      <div className="cont-row align-items-start">
                        {profile?.socialmedia?.map((item: any, i: any) => {
                          return (
                            <>
                              <p className="fs-13 fw-med mb-1">{item?.name}</p>
                              <span> {item?.link}</span>
                              <br />
                              <br />
                            </>
                          );
                        })}
                      </div>
                    </div>
                  </div> */}
                  {/* <!-- Social Media ---> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- Content Section Ends here --> */}
      </section>
      {/* <!-- Main Wrapper Ends here --> */}
      {/* <!-- Footer Starts here --> */}
      <footer className="container-fluid">
        <p className="mb-0">Copyright 2023 © Forte. All right reserved</p>
      </footer>
      {/* <!-- Footer Ends here --> */}
    </>
  );
};

export default ViewProfileInfo;
